<template>
  <div>
    <div class="overview_cont">
      <!-- 园区概况卡片 -->
      <el-card class="park">
        <div class="park_title">
          <h2>园区概况</h2>
        </div>
        <el-form :model="Park_Profile" ref="FormCont1" label-width="140px" size="small " class="parkFrom">
          <el-form-item label="总建筑面积:" prop="all_area"
            :rules="[{ trigger: 'blur', type: 'number', message: '总建筑面积必须为数字值' }]">
            <el-input type="number"  v-model.number="Park_Profile.all_area" autocomplete="off"></el-input>
            <span> ㎡</span>
          </el-form-item>
          <el-form-item label="总楼层:" prop="all_floor"
            :rules="[{ trigger: 'blur', type: 'number', message: '总楼层必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.all_floor" autocomplete="off"></el-input>
            <span> 层</span>
          </el-form-item>
          <el-form-item label="车位总数:" prop="car_num"
            :rules="[{ trigger: 'blur', type: 'number', message: '车位总数必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.car_num" autocomplete="off"></el-input>
            <span> 个</span>
          </el-form-item>
          <el-form-item label="层高:" prop="floor_height"
            :rules="[{ trigger: 'blur', type: 'number', message: '层高必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.floor_height" autocomplete="off"></el-input>
            <span> 米</span>
          </el-form-item>
          <el-form-item label="可租赁面积:" prop="can_rent_area"
            :rules="[{ trigger: 'blur', type: 'number', message: '可租赁面积必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.can_rent_area" autocomplete="off"></el-input>
            <span> ㎡</span>
          </el-form-item>
          <el-form-item label="已租面积:" prop="has_rent_area"
            :rules="[{ trigger: 'blur', type: 'number', message: '已租面积必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.has_rent_area" autocomplete="off"></el-input>
            <span> ㎡</span>
          </el-form-item>
          <el-form-item label="未租面积:" prop="not_rent_area"
            :rules="[{ trigger: 'blur', type: 'number', message: '未租面积必须为数字值' }]">
            <el-input type="number" v-model.number="Park_Profile.not_rent_area" autocomplete="off"></el-input>
            <span> ㎡</span>
          </el-form-item>
          <el-form-item label="租赁情况:">
            <el-input type="number" :disabled="true" v-model.number="areaChange_not_rent_area" autocomplete="off">
            </el-input>
            <span> %</span>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- 企业概况卡片 -->
      <el-card class="enterprise">
        <div class="enterprise_title">
          <h2>企业概况</h2>
        </div>
        <el-form :model="Park_Profile" ref="FormCont2" label-width="140px" size="small " class="enterpriseFrom">
          <el-form-item label="入驻企业:" prop="company_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.company_num" autocomplete="off"></el-input>
            <span> 家</span>
          </el-form-item>
          <el-form-item label="上市企业数:" prop="top_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.top_num" autocomplete="off"></el-input>
            <span> 家</span>
          </el-form-item>
          <el-form-item label="国高新企业数:" prop="high_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.high_num" autocomplete="off"></el-input>
            <span> 家</span>
          </el-form-item>
          <el-form-item label="入驻商家:" prop="in_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="number" v-model.number="Park_Profile.in_num" autocomplete="off"></el-input>
            <span> 家</span>
          </el-form-item>
          <el-form-item label="企业员工数:" prop="employee_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.employee_num" autocomplete="off"></el-input>
            <span> 人</span>
          </el-form-item>
          <el-form-item label="科技人才数:" prop="tech_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.tech_num" autocomplete="off"></el-input>
            <span> 人</span>
          </el-form-item>
          <el-form-item label="企业创新知识产权:" prop="new_right_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="age" v-model.number="Park_Profile.new_right_num" autocomplete="off"></el-input>
            <span> 项</span>
          </el-form-item>
          <el-form-item label="企业知识产权:" prop="right_num" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input type="number" v-model.number="Park_Profile.right_num" autocomplete="off"></el-input>
            <span> 项</span>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="save_btn">
        <el-button type="primary" @click="submitForm">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 园区概况的数据
      Park_Profile: {
        id: '',
        all_area: '',
        all_floor: '',
        car_num: '',
        floor_height: '',
        can_rent_area: '',
        has_rent_area: '',
        not_rent_area: '',
        company_num: '',
        top_num: '',
        high_num: '',
        in_num: '',
        employee_num: '',
        tech_num: '',
        new_right_num: '',
        right_num: ''
      }
    }
  },
  computed: {
    // 租赁情况
    areaChange_not_rent_area () {
      return (this.Park_Profile.has_rent_area / (this.Park_Profile.has_rent_area + this.Park_Profile.not_rent_area)).toFixed(2)
    }
  },
  async created () {
    // 获取园区概况的数据
    const { data: res } = await this.$http.get('zgcds_park_profile')
    this.Park_Profile = res.data[0]
    for (const i in this.Park_Profile) {
      this.Park_Profile[i] = Number(this.Park_Profile[i])
    }
  },
  methods: {
    // 保存企业概况的数据
    async submitForm () {
      this.$refs.FormCont1.validate(async valid => {
        if (!valid) return false
        this.$refs.FormCont2.validate(async valid2 => {
          if (!valid2) return false
          const res = await this.$http.post(`zgcds_park_profile/${this.Park_Profile.id}`, {
            all_area: this.Park_Profile.all_area,
            all_floor: this.Park_Profile.all_floor,
            car_num: this.Park_Profile.car_num,
            floor_height: this.Park_Profile.floor_height,
            can_rent_area: this.Park_Profile.can_rent_area,
            has_rent_area: this.Park_Profile.has_rent_area,
            not_rent_area: this.Park_Profile.not_rent_area,
            company_num: this.Park_Profile.company_num,
            top_num: this.Park_Profile.top_num,
            high_num: this.Park_Profile.high_num,
            in_num: this.Park_Profile.in_num,
            employee_num: this.Park_Profile.employee_num,
            tech_num: this.Park_Profile.tech_num,
            new_right_num: this.Park_Profile.new_right_num,
            right_num: this.Park_Profile.right_num,
            _method: 'put'
          })
          if (res) this.$message({ message: '保存成功', type: 'success' })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.overview_cont {
  padding: 1rem;

  .park,
  .enterprise {
    margin-bottom: 1rem;

    .park_title,
    .enterprise_title {
      position: relative;

      h2 {
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }

      ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }

    .parkFrom,
    .enterpriseFrom {
      width: 84%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;

      .el-form-item {
        width: 24%;

        .el-input {
          width: 75%;
        }
      }
    }
  }

  .save_btn {
    display: flex;
    justify-content: center;

    .el-button--primary {
      background-color: #015478;
      border-color: #015478;
      padding: 12px 60px;
    }
  }
}

.situation_cont {
  .situation {
    margin-bottom: 1rem;

    .title {
      position: relative;

      h2 {
        padding-left: 0.7rem;
        font-size: 1rem;
        font-weight: bold;
      }

      ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 1px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }

    .table {
      margin: 1rem 0;
    }
  }
}
</style>
